import React from 'react';
// styles
import './styles/About.scss'

const About = () => (
	<article className="about">
		<h1>This is an about page</h1>

		<p>
			It's been one week since you looked at me Cocked your head to
			the side and said, "I'm angry" Five days since you laughed at
			me saying "Get that together, come back and see me" Three days
			since the living room I realized it's all my fault but
			couldn't tell you Yesterday, you'd forgiven me But it'll still
			be two days till I say I'm sorry. Hold it now and watch the
			hoodwink As I make you stop, think You'll think you're looking
			at Aquaman I summon fish to the dish Although I like the
			Chalet Swiss I like the sushi 'Cause it's never touched a
			frying pan Hot like wasabi when I bust rhymes Big like LeAnn
			Rimes Because I'm all about value Bert Kaempfert's got the mad
			hits You try to match wits You try to hold me but I bust
			through Gonna make a break and take a fake I'd like a stinkin'
			achin' shake I like vanilla, it's the finest of the flavours
			Gotta see the show 'cause then you'll know The vertigo is
			gonna grow 'cause it's so dangerous You'll have to sign a
			waiver.
		</p>

		<p>
			How can I help it if I think you're funny when you're mad?
			Trying hard not to smile though I feel bad I'm the kind of guy
			who laughs at a funeral Can't understand what I mean? Well,
			you soon will I have a tendency to wear my mind on my sleeve I
			have a history of taking off my shirt. It's been one week
			since you looked at me Threw your arms in the air and said,
			"You're crazy" Five days since you tackled me I've still got
			the rug burns on both my knees It's been three days since the
			afternoon You realized it's not my fault, not a moment too
			soon Yesterday, you'd forgiven me And now I sit back and wait
			till you say you're sorry.
		</p>

		<p>
			Chickity China the Chinese chicken You have a drumstick and
			your brain stops tickin' Watchin' X-Files with no lights on
			We're dans la maison I hope the Smoking Man's in this one Like
			Harrison Ford I'm getting frantic Like I'm tantric Like
			Snickers, guaranteed to satisfy Like Kurosawa, I make mad
			films 'Kay, I don't make films But if I did they'd have a
			Samurai Gonna get a set a' better clubs Gonna find the kind
			with tiny nubs Just so my irons aren't always flying off the
			back-swing Gotta get in tune with Sailor Moon 'Cause that
			cartoon has got the boom anime babes That make me think the
			wrong thing.
		</p>

		<p>
			How can I help it if I think you're funny when you're mad?
			Tryin' hard not to smile though I feel bad I'm the kind of guy
			who laughs at a funeral Can't understand what I mean? You soon
			will I have a tendency to wear my mind on my sleeve I have a
			history of losing my shirt. It's been one week since you
			looked at me Dropped your arms to your sides and said, "I'm
			sorry" Five days since I laughed at you and said "You just did
			just what I thought you were gonna do" Three days since the
			living room We realized we're both to blame, but what could we
			do? Yesterday, you just smiled at me 'Cause it'll still be two
			days till we say we're sorry. It'll still be two days till we
			say we're sorry It'll still be two days till we say we're
			sorry Birchmount Stadium, home of the Robbie.
		</p>
	</article>
)
export default About;
