import React, {useState} from 'react';
import axios from "axios";

import {Song} from "../types";
// styles
import './styles/Tunes.scss'

//children
import TuneList from '../components/tunes/TunesList'
import TunesSearchForm from "../components/tunes/TunesSearchForm"

// data types
interface SongFromITunes {
	trackId: number
	artistName: string
	previewUrl: string
	artworkUrl100?: string
	trackName: string
	collectionName: string
	kind?: string
}


const Tunes = () => {
	//state
	const [songs, setSongs] = useState([]);

	// callback
	const handleSearch = (query: string) => {
		axios
			.get(
				`https://itunes.apple.com/search
				?term=${encodeURI(query)}
				&entity=musicTrack
				&limit=5`
			)
			.then(response => {
				let iTunesSongs = response.data.results
					.filter((song: SongFromITunes) => song.kind === 'song')
					.map((song: SongFromITunes) => extractData(song))

				setSongs(iTunesSongs)
			})
	}

	const extractData = ({
							 trackId: id,
							 artistName: artist,
							 previewUrl: audioFile,
							 artworkUrl100: artwork,
							 trackName: title,
							 collectionName: album
						 }: SongFromITunes) => {
		return {id, artist, audioFile, artwork, title, album} as Song
	}

	return (
		<article className="tunes">
			<h1>Tunes</h1>
			<TunesSearchForm onSearch={handleSearch}/>
			<TuneList songs={songs}/>
		</article>
	)
}

export default Tunes;
