import React, {ChangeEvent, FormEvent, useRef} from 'react';
import { debounce} from "lodash-es";

import './styles/TunesSearchForm.scss'

interface searchProps {
	onSearch : (query:string) => void
}
const TunesSearchForm = (props:searchProps) => {
	const searchInput = useRef<any>(null);

	//submit form
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		searchForMusic();
	}

	//input element
	//debounce - utilitka, něco jako timer - nastaví 500ms čekačku, než spustí handler
	const handleInput = debounce( (e: ChangeEvent<HTMLInputElement>) => {
		searchForMusic();
	}, 500)

	//funmction for looking of songs
	const searchForMusic = () => {
		let searchString = searchInput.current?.value
		if (searchString) props.onSearch(searchString)
	}
	return (
		<div>
			<form onSubmit={handleSubmit}>
				<input type="text"
					   autoFocus
					   name="search"
					   ref={searchInput}
					   onChange={handleInput}
					   className="search"/>
			</form>
		</div>
	);
};

export default TunesSearchForm;
