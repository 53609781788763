import React from 'react';

import {Route, Routes} from "react-router-dom";

import './assets/styles/App.scss';

import Home from "./views/Home";
import Tunes from "./views/Tunes";
import About from "./views/About";
import TheNavigation from "./components/TheNavigation";


const App = () => (
	<div className="App">
		<header>
			<TheNavigation/>
		</header>
		<main className="content">
			<Routes>
				<Route path={"/"} element={<Home/>}/>
				<Route path={"/tunes"} element={<Tunes/>}/>
				<Route path={"/about"} element={<About/>}/>
			</Routes>
		</main>
	</div>
)

export default App;
