import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import App from './App';

import {BrowserRouter as Router} from "react-router-dom";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
	  <Router>
		  <App />
	  </Router>
  </React.StrictMode>,
  rootElement
);
