import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import {Song} from "../../types";
import TunesSong from "./TunesSong";
import './styles/TunesList.scss'

interface SongsProps {
	songs: Song[]
}
const TunesList = (props:SongsProps) => {
	const {songs} = props
	// template
	return (
		<TransitionGroup component="ul" className="tunes-list">
			{songs.map(song => (
				<CSSTransition key={song.id} timeout={200} classNames="song">
					<li key={song.id}>
						<TunesSong song={song} />
					</li>
				</CSSTransition>
			))}
		</TransitionGroup>
	)
};

export default TunesList;
