import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import styles from '../assets/styles/TheNavigation.module.scss';

const isActiveStyle=styles.active;

const TheNavigation = () => {
	return (
		<nav className={styles.navigation}>
			<NavLink to={"/"} className={({isActive}) => (isActive ? isActiveStyle : '')}>Home</NavLink>
			<NavLink to={"/tunes"} className={({isActive}) => (isActive ? isActiveStyle : '')}>Tunes</NavLink>
			<NavLink to={"/about"} className={({isActive}) => (isActive ? isActiveStyle : '')}>About</NavLink>
		</nav>
	)
}
export default TheNavigation;
